.pointer {
    cursor: pointer;
}

.strike {
    text-decoration: line-through;
}

.form-hint {
    color: #b5b5b5;
    font-size: 70%;
}

.sidebar {
    background: whitesmoke;
    min-height: 100%;
}
.sidebar-mobile {
    transform: translateX(0) !important;
    transition: all 300ms ease 0s !important;
    position: fixed;
    min-width: 12rem;
    z-index: 3;
}
.sidebar-background {
    z-index: 2;
    position: fixed;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    background: RGBA(0, 0, 0, .7);
    display: none;
}
.sidebar-background.active {
    display: block;
}


.tag.light-primary {
    background: #5fe0cd;
    color: #fff;
}

.tag.light-info {
    background: #7dc4f3;
    color: #fff;
}

.tag.light-danger {
    background: #fb6262d1;
    color: #fff;
}

.tag.light-gray {
    background: #858484;
    color: #fff;
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
	border-radius:10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.logo {
    width: 6rem;
    margin: 0.7rem;
}
.navigation {
    padding-left: 0.7rem;
    position: sticky;
    top: 0.7rem;
}
.navigation .category {
    margin-top: 1.5rem;
}
.navigation .item {
    margin-top: 0.3rem;
    padding-right: 0.7rem;
    display: block;
    color: #7a7a7a;
}
.navigation .item:hover {
    color: #000;
}
.navigation .item.active {
    color: #3273dc;
    border-right: 3px solid #3273dc;
}
.content-wrapper {
    border-left: solid 1px #e3e3e3;
    min-height: 100vh;
    background: #fff;
}
.header {
    border-bottom: solid 1px #e3e3e3;
    padding: 0.8rem 0 0.8rem 3rem;
    background: #fff;
    z-index: 1;
}
.header>.columns {
    position: relative;
}
.content {
    padding: 2rem 0 0 3rem;
}
.content h1, .content h2, .content h3, .content h4 {
    font-weight: 400;
}
.search {
    border: none;
    outline: none;
    font-size: 1em;
    width: 100%;
    min-height: 2rem;
    padding-left: 1rem;
}
.search-icon {
    margin-bottom: -0.6rem;
}
.menu-icon {
    width: 2rem;
    height: 2rem;
    display: none !important;
    margin: 0 1rem -0.6rem 0;
    cursor: pointer;
}
.footer-wrapper {
    background: #fff;
    border-top: solid 1px #e3e3e3;
    margin: 3rem 0;
    padding: 1.8rem 0;
}

/* Responsive */
@media (max-width: 768px) {
    .sidebar {
        position: fixed;
        background: #fff;
        transform: translateX(-14rem);
        transition: all 300ms ease 0s;
    }
    .logo {
        position: relative;
        padding-left: 1rem;
    }
    .navigation {
        position: relative;
        padding-left: 1.5rem;
    }
    .content {
        padding: 2rem;
    }
    .header {
        padding-left: 2rem;
        position: sticky;
        top: 0;
    }
    .menu-icon {
        display: inline-flex !important;
    }
    .search-icon {
        margin-bottom: -0.6rem;
    }
}

.search-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: hsla(0, 0%, 100%, .8);
    visibility: hidden;
    opacity: 0;
    transition: opacity .25s ease-in-out;
    z-index: 10000,
}

.content>.body img {
    border: 2px solid whitesmoke;
    border-radius: 2px;
}

.search-background.visible {
    visibility: visible;
    opacity: 1;
}

.search-results>.column {
    padding: 0;
}

.search-results-list {
    display: none;
}

.search-results {
    margin-top: 0;
    transition: 200ms;
}

.search-results.visible {
    box-shadow:
        0 15px 35px 0 rgba(50, 50, 93, .1),
        0 5px 15px 0 rgba(0, 0, 0, .07);
    z-index: 10010;
    visibility: visible;
    opacity: 1;
    background: white;
    position: absolute;
    padding: .75rem;
    margin-left: -.75rem;
    margin-top: -.75rem;
    width: 85%;
}
.search-results.visible .search-results-list {
    display: block;
}

.search-input.visible {
    box-shadow:
        0 15px 35px 0 rgba(50, 50, 93, .1),
        0 5px 15px 0 rgba(0, 0, 0, .07);
    z-index: 10010;
    visibility: visible;
    opacity: 1;
    background: white;
    position: absolute;
    width: 50%;
}

.search-results-list > li {
    margin-top: .5rem;
}

a:visited {
  color: #194a99;
}

.is-text-overflow {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
